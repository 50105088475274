import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { STATE_CA } from '@app-consts';
import { ApiService } from '@app-services';
import { EnumErrorTypes } from '@app-models';
import { ValidatorsEx } from '@app-utilities';

@Component({
  selector: 'app-create-partner-agency',
  templateUrl: './create-partner-agency.component.html'
})
export class CreatePartnerAgencyComponent implements OnInit {
  @Input() heading = 'Add Collaborative Partner';
  @Input() typeOptions: Model.SelectOption[] = [];
  @Input() helpText = 'Add information about the partner that will be contributing to the effort.';
  @Input() pickListFieldName: string = 'Partner';
  @Input() collectEIN: boolean = false;
  @Input() collectTypeOnly: boolean = false;
  @Output() closeAction: EventEmitter<null> = new EventEmitter();
  @Output() cancelAction: EventEmitter<null> = new EventEmitter();
  @Output() createSuccess: EventEmitter<Model.Institution> = new EventEmitter();

  addPartnerForm: FormGroup;
  partnerIsUnique = true;
  showSameNameAlert = false;

  constructor(
    private apiService: ApiService,
    private _fb: FormBuilder,
    private store: Store<State>,
  ) { }

  ngOnInit(): void {
    const controls = {
      name: ['', [Validators.required]],
      type: [undefined, [Validators.required]],
      address1: [''],
      city: [],
      zip: ['', [ValidatorsEx.zipCode]],
      website: [],
      phone: [undefined, [ValidatorsEx.phoneNumber]]
    };

    if (this.collectEIN) {
      controls['ein'] = [undefined, [Validators.required, ValidatorsEx.einNumber]];
    }

    this.addPartnerForm = this._fb.group(controls);
  }

  createPartnerInstitution() {
    const institution = {};
    Object.entries(this.addPartnerForm.value).forEach(([key, value]) => {
      if (value) {
        if (key === 'type') {
          institution[key] = this.typeOptions.find(type => type.value === value).label;
        } else {
          institution[key] = value;
        }
      }
    });

    if (this.addPartnerForm.get('city').value) {
      // add CA as state if city was added.
      institution['state'] = STATE_CA;
    }

    this.store.dispatch(Actions.Layout.showBusySpinner(true));
    this.apiService.createInstitution(institution).subscribe(
      (res) => {
        this.store.dispatch(Actions.Layout.showBusySpinner(false));
        this.createSuccess.emit(res);
      },
      (err) => {
        this.store.dispatch(Actions.Layout.showBusySpinner(false));
        this.store.dispatch(Actions.App.setError({
          type: EnumErrorTypes.api,
          location: this.constructor.name,
          show: true,
          raw: err,
          message: 'Failed to create partner agency. Please try again.'
        }))
      });
  }

  /**
   * Checks the service to see if the name for a new partner already exists,
   * and therefore should not be duplicated
   */
  checkForPartnerDupes(force = false) {
    const instName: string = this.addPartnerForm.get('name').value.toLowerCase();
    const instType: string = this.addPartnerForm.get('type').value;

    // Do the API lookup if: force == true,
    //  OR the current selection is an exact dupe or dupe name (ie, we want to know right away if a new keypress will change this
    //  OR every 3rd character in the name.
    if (force || !this.partnerIsUnique || this.showSameNameAlert || !(instName.length % 3)) { // Check the service every 3 characters
      this.apiService.listInstitutions({ match_strings: instName, limit: 20 }).subscribe(res => {
        res.forEach(i => {
          i.name = i.name && i.name.toLowerCase();
          i.long_name = i.long_name && i.long_name.toLowerCase();
        });
        const dupeName = res.some(i => (i.name === instName) || (i.long_name === instName));
        const dupeNameAndType = res.some(i => (i.type === instType) && (i.name === instName || i.long_name === instName));

        this.partnerIsUnique = !dupeNameAndType;
        this.showSameNameAlert = dupeName;
      });
    }
  };
}
