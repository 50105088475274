<div [ngClass]="{ 'toast-modal': !disableModal }" poFocusTrap>
  <div class="toast toast--{{level}} flex" data-qa="app_alert_toaster" id="qa_alert_toaster">

    <div class="toast__icon">
      <icon *ngIf="level !== 'success'" iconId="exclamation"></icon>
      <icon *ngIf="level == 'success'" iconId="checkmark"></icon>
    </div>

    <div class="toast__body">
      <ng-content></ng-content>
    </div>

    <div class="grow-1">
      <button
        *ngIf="!!buttonDismiss"
        type="button"
        [textContent]="buttonDismiss"
        (click)="closeAlert('dismiss')"
        class="action-button"
        [ngClass]="defaultCancel ? 'action-button--primary' : 'action-button--secondary'"
        id="qa_alert_close_button">
      </button>

      <button
        *ngIf="!!buttonAction"
        type="button"
        [textContent]="buttonAction"
        (click)="doAction('action')"
        class="action-button"
        [ngClass]="defaultCancel ? 'action-button--secondary' : 'action-button--primary'"
        id="qa_alert_action_button">
        <div class="loader-spinner-inline"></div>
      </button>
    </div>
  </div>

</div>