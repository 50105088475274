<full-page-modal
  small="true"
  heading="{{heading}}"
  button-cancel="Cancel"
  button-action="Add"
  [button-action-disabled]="addPartnerForm.invalid || !partnerIsUnique"
  (action)="createPartnerInstitution()"
  (cancel)="cancelAction.emit()"
  (dismiss)="closeAction.emit()">

  <div class="help-text">
    <p>{{ helpText }}</p>
  </div>

  <form [formGroup]="addPartnerForm" autocomplete="off">
    <input-field labelText="{{pickListFieldName}} Name">
      <input inputRef id="addPartner_name_input" formControlName="name" aria-required="true" type="text"
        (keyup)="checkForPartnerDupes()"
        (blur)="checkForPartnerDupes(true)">
      <p class="error-text" *ngIf="!partnerIsUnique" level="error">
        An agency with the same name and type is already known to NOVA.
        Please click Cancel and choose the existing agency from the {{ pickListFieldName }}s list.
      </p>
    </input-field>
    <p class="help-text" *ngIf="showSameNameAlert && partnerIsUnique">
      An agency with the same name is already known to NOVA.
      Is that the one you want? If so, please click <b>Cancel</b> and choose the existing agency from the <b>{{ pickListFieldName }}s</b> list.
    </p>

    <po-select labelText="{{pickListFieldName}} Type"
      id="addPartner_type_select"
      placeholderText="Select {{pickListFieldName}} Type"
      [options]="typeOptions"
      [selectOnce]="true"
      (optionSelected)="checkForPartnerDupes(true)"
    >
      <select inputRef formControlName="type" class="po-select__select" aria-required="true"></select>
    </po-select>

    <ng-container *ngIf="!collectTypeOnly">
      <input-field labelText="Address">
        <input inputRef id="addPartner_address_input" formControlName="address1" type="text">
      </input-field>

      <input-field labelText="City">
        <input inputRef id="addPartner_city_input" formControlName="city" type="text">
      </input-field>

      <input-field labelText="Zip Code">
        <input inputRef id="addPartner_zip_input" formControlName="zip" type="text">
      </input-field>

      <input-field labelText="Website">
        <input inputRef id="addPartner_website_input" formControlName="website" type="text">
      </input-field>

      <input-field labelText="Phone Number">
        <input inputRef id="addPartner_phone_input" formControlName="phone" type="phone" phone-transform>
      </input-field>

      <input-field *ngIf="collectEIN" labelText="EIN">
        <input inputRef id="addPartner_ein_input" formControlName="ein" type="text" placeholder="XX-XXXXXXX">
      </input-field>
    </ng-container>
  </form>
</full-page-modal>
