import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State, Model, Actions as DomainActions } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { METRICS_ACTION_TYPES } from './metrics.action';

/**
 * Injectable effects class.
 *
 */
@Injectable()
export class MetricsEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  /**
   * Fetches metrics
   */
   loadMetrics$ = createEffect(() => this.actions$.pipe(
    ofType(METRICS_ACTION_TYPES.LOAD_GP_METRICS),
    map(toPayload),
    mergeMap((proposal: Model.ProposalItem) => {
      return this.apiService.getGPMetrics(proposal.lead_institution.id).pipe(
        mergeMap(response => {
          response.forEach(metric => {
            if (metric['code']) {
              metric.value = 'No data available';
            }
          });

          return of(DomainActions.Metrics.loadSuccess('gp', response));
        }),
        catchError(err => of(DomainActions.Metrics.serviceFail(err)))
      );
    })
  ));

   loadLVGMetrics$ = createEffect(() => this.actions$.pipe(
    ofType(METRICS_ACTION_TYPES.LOAD_LVG_METRICS),
    map(toPayload),
    mergeMap((lead_institution_id: number) => {
      return this.apiService.getLVGMetrics(lead_institution_id).pipe(
        mergeMap(response => {
          return of(DomainActions.Metrics.loadSuccess('lvg', response));
        }),
        catchError(err => of(DomainActions.Metrics.serviceFail(err)))
      );
    })
  ));

   loadSEPMetrics$ = createEffect(() => this.actions$.pipe(
    ofType(METRICS_ACTION_TYPES.LOAD_SEP_METRICS),
    map(toPayload),
    mergeMap((lead_institution_id: number) => {
      return this.apiService.getSEPMetrics_old(lead_institution_id).pipe(
        mergeMap(response => {
          return of(DomainActions.Metrics.loadSuccess('sep', response));
        }),
        catchError(err => of(DomainActions.Metrics.serviceFail(err)))
      );
    })
  ));

   loadSWPMetrics$ = createEffect(() => this.actions$.pipe(
    ofType(METRICS_ACTION_TYPES.LOAD_SWP_METRICS),
    map(toPayload),
    mergeMap(({ institutionIds, durationIds, metricDefinitionIds }) => {
      return this.apiService.getSWPMetrics(institutionIds, durationIds, metricDefinitionIds).pipe(
        mergeMap(response => {
          return of(DomainActions.Metrics.loadSuccess('swp', response));
        }),
        catchError(err => of(DomainActions.Metrics.serviceFail(err)))
      );
    })
  ));

   loadLBMetrics$ = createEffect(() => this.actions$.pipe(
    ofType(METRICS_ACTION_TYPES.LOAD_LB_METRICS),
    map(toPayload),
    mergeMap(({ institutionIds, durationIds, metricDefinitionIds }) => {
      return this.apiService.getLBMetrics(institutionIds, durationIds, metricDefinitionIds).pipe(
        mergeMap(response => {
          return of(DomainActions.Metrics.loadSuccess('lb', response));
        }),
        catchError(err => of(DomainActions.Metrics.serviceFail(err)))
      );
    })
  ));

   loadCAEPMetrics$ = createEffect(() => this.actions$.pipe(
    ofType(METRICS_ACTION_TYPES.LOAD_CAEP_METRICS),
    map(toPayload),
    mergeMap(({ institutionIds, durationIds, metricDefinitionIds, version }) => {
      return this.apiService.getCAEPMetrics(institutionIds, durationIds, metricDefinitionIds, version).pipe(
        mergeMap(response => {
          return of(DomainActions.Metrics.loadSuccess('caep', response));
        }),
        catchError(err => of(DomainActions.Metrics.serviceFail(err)))
      );
    })
  ));
}
