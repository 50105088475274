import { Utilities } from '@app-models';
import { Model } from '@app-ngrx-domains';
import { IEffortArea } from '../effort-area';
import { ProposalBase } from '../proposal-base';
import { AREA_NAMES } from '../../consts';

export interface IThreeYearPlan extends IEffortArea {
  // Details
  executive_summary: string;
  assurance_ids: Array<Model.AttributeValue>

  // Assessment
  assessment_overview: string;
  regional_alignment: string;
  educational_needs: string;
  entities_contribution: string;
  program_area_participants: Array<Model.EAProgramAreaParticipants>;
  service_providers: Array<Model.AttributeValue>;
  regional_service_evaluation: string;
  partner_organizations: Array<Model.AttributeValue>;

  // Metrics
  vision_goals: Array<Model.EAVisionGoal>;
  target_goals: Array<Model.EATargetGoal>;
  member_spending_targets: Array<Model.EAMemberSpendingTargets>;

  // Objectives
  three_year_plan_objectives: Array<Model.EAThreeYearPlanObjectives>;
  objective1_description: string;
  objective2_strategies: Array<Model.AttributeValue>;
  objective2_strategies_other: string;
  objective2_description: string;
  objective3_programmatic_strategies: Array<Model.AttributeValue>;
  objective3_programmatic_strategies_other: string;
  objective3_operational_strategies: Array<Model.AttributeValue>;
  objective3_operational_strategies_other: string;
  objective3_description: string;

  // Activities & Outcomes
  workplan_activities: Array<Model.EAWorkplanActivity>;

  // Funds Evaluation
  funds_evaluation: string;

  assessment_member_types: Array<Model.AttributeValue>;
  assessment_member_types_other: string;
  assessment_data_sources: Array<Model.AttributeValue>;
  assessment_data_sources_other: string;
  data_collection_process: string;
  education_alignment: string;
  education_workforce_plans: Array<Model.AttributeValue>;
  education_workforce_plans_other: string;
  regional_alignment_priorities: Array<Model.AttributeValue>;
  regional_alignment_priorities_other: string;
  needs_categories: Array<Model.AttributeValue>;
  needs_categories_other: string;
  needs_context: string;
  challenges_context: string;
  program_challenges: Array<Model.AttributeValue>;
  program_challenges_other: string;
  program_populations: Array<Model.AttributeValue>;
  program_populations_other: string;
  service_provider_industries: Array<Model.AttributeValue>;
  service_provider_industries_other: string;
  industries_context: string;
  three_year_plan_contributors: Array<Model.EAThreeYearPlanContributor>;
}

export interface IThreeYearPlanObjectives extends IEffortArea {
  // title, description
  sort_order: number;
}

export interface IThreeYearPlanContributor extends IEffortArea {
  name: string;
  role: string;
  role_other: string;
  contribution: string;
  contribution_other: string;
}

export class ThreeYearPlan {

  /**
   * Returns target year's three year plan.
   * @static
   * @param {number} target_duration_id
   * @param {Array<Model.ThreeYearPlan>} threeYearPlans
   * @returns {*} ThreeYearPlan
   */
  static targetYearThreeYearPlan(target_duration_id: number, threeYearPlans: Array<Model.EAThreeYearPlan>): Model.EAThreeYearPlan {
    const result = (threeYearPlans || []).sort((a, b) => a.duration_id > b.duration_id ? -1 : 1).find(plan => {
      return Utilities.between(target_duration_id - plan.duration_id, 0, 2)
    });
    return result;
  }

  static isDraft(proposal, durationId): boolean {
    const plan = this.targetYearThreeYearPlan(durationId, proposal.three_year_plans);
    return ProposalBase.isAreaDraft(proposal, AREA_NAMES.THREE_YEAR_PLAN, plan && plan.duration_id);
  }

  static isCertified(proposal, durationId): boolean {
    const plan = this.targetYearThreeYearPlan(durationId, proposal.three_year_plans);
    return ProposalBase.isAreaCertified(proposal, AREA_NAMES.THREE_YEAR_PLAN, plan && plan.duration_id);
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAThreeYearPlan = IThreeYearPlan;
    export type EAThreeYearPlanObjectives = IThreeYearPlanObjectives;
    export type EAThreeYearPlanContributor = IThreeYearPlanContributor;
  }
}
