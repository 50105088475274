export const CFAD_ASSURANCES = [
  {
    header: 'Membership & Decision-Making',
    assurances: [
      'I certify that any community college district, school district, or county office of education, or any joint powers authority consisting of community college districts, school districts, county offices of education, or a combination of these, located within the boundaries of the adult education region shall be permitted to join the consortium as a member (EC 84905 (a)(b). (See Membership Box above).',
      'I certify that only members as described above (and in EC 84905) are allowed to join my consortium as members and participate in decision making and approvals whether in a public meeting, or via the NOVA planning, budgeting & expense reporting system.',
      'I certify that as a condition of joining a consortium, as a member, I shall commit to reporting any funds (as described in EC 84916) available to that member for the purposes of education and workforce services for adults and the uses of those funds through the annual Program Area exercise in NOVA for reporting leveraged funds, and instructional hours.',
      'I certify that as a member of the consortium my district shall be represented only by an official designated by the governing board of the member (EC 84905 (c)).',
      'I certify that as a member of the consortium, I shall participate in any decision made by the consortium (EC 84905 (d)(1)(A)).',
      'I certify that all decision made by the consortium and its members is final (EC 84905 (d)(1)(F)).',
      'I certify that I will adhere to the consortium rules and procedures and, as agreed upon by the consortium members, to any additional by-laws, charters, etc.'
    ]
  },
  {
    header: 'Public Meetings',
    assurances: [
      'I certify that a proposed decision is considered at an open, properly noticed public meeting of the consortium at which members of the public may comment (EC 84905 (d)(1)(B)).',
      'I certify that the consortium has provided the public with adequate notice of a proposed decision and considered any comments submitted by members of the public, and any comments submitted by members of the public have been distributed publicly (EC 84905 (d)(1)(C)).',
      'I certify that the consortium has requested comments regarding a proposed decision from other entities located in the adult education region that provide education and workforce services for adults (EC 84905 (d)(1)(D)(i)).',
      'I certify that the consortium has requested comments regarding a proposed decision from other entities located in the adult education region that provide education and workforce services for adults (EC 84905 (d)(1)(D)(i)).',
      'I certify that the consortium has considered input provided by pupils, teachers employed by local educational agencies, community college faculty, principals, administrators, classified staff, and the local bargaining units of the school districts and community college districts before it makes a decision (EC 84905 (d)(1)(E)).',
      'I certify that in addition to the meeting requirements listed in EC 84905, and as agreed upon by the consortium members, that I will follow the public meeting requirements listed in the Ralph M. Brown Act as the Brown Act applies to the governing body of any “local body created by state or federal statute.” (Ed. Code, section 54952.)'
    ]
  },
  {
    header: 'Reporting Requirements',
    assurances: [
      'I certify that I will participate in completing and updating any consortium long range and/or short range planning efforts and/or budget work plans (EC 84906, 84914(a)).',
      'I certify that all CAEP expenses have been expended in the CAEP seven program areas, and services provided are consistent with the 3-year plan, the annual plan, and my district’s work plan & budget as submitted in NOVA (EC 84913 (1-7), 84906, 8914(a)).',
      'I certify that my expenditures of CAEP funds match the objectives/activities included in the annual plan and the member work plan (EC 84906, 84914(a)).',
      'I certify that my expenditures of CAEP funds adhere to the allowable uses of funds as identified in the CAEP Fiscal Management Guide.',
      'I certify that I will report student level enrollment data and outcomes as prescribed by the State CAEP Office (EC 84920).',
      'I certify that I will share financial expenditure and progress reports with the members of my regional consortium.',
      'I certify that I understand that as a member if I do not meet any of these items I have certified, I will be deemed an ineffective member which may result in a loss or reduction of CAEP funding (EC 84914(b)).',
      'I certify that all CAEP expenses have been expended only for the education of persons 18 years of age or older (EC 84901(a)).'
    ]
  }
];

export const DEFAULT_FUND_NAMES: string[] = [
  'California Adult Education Program', 'CalWORKs', 'NonCredit',
  'Perkins', 'WIOA II', 'LCFF', 'Fees',
  'K12 Adult Ed Jail Funds', 'Contracted Services'
];

export const CONSORTIA_ENROLLMENT_METRIC = '200AE';
export const MEMBER_ENROLLMENT_METRIC = '202AE';

export const REQUIRED_3YP_METRIC_IDS: { [key: string]: Array<string> } = {
  consortia: [CONSORTIA_ENROLLMENT_METRIC],
  member: [MEMBER_ENROLLMENT_METRIC]
}

export const AMENDMENT_TYPES = {
  REVISE: 'May revise',
  STANDARD: 'Standard member transfer',
  NEW_MEMBER: 'New member',
  CARRYOVER_COMPLIANCE: 'Carryover Compliance (EC 84914)'
}

export const CAEP_V2_DURATION_ID: number = 2023;
export const CAEP_AB1491_START_YEAR: number = 2024;
export const CAEP_V2_METRICS_YEAR: number = 2026;
export const CFAD_ALLOCATION_CERTIFICATION_YEAR: number = 2026;
export const CFAD_ALLOCATION_CERTIFICATION_TEXT: string = `I certify that this CFAD is true and accurate. Our consortium certifies that the allocations listed
correctly represent all funded members and their respective amounts.`;
