import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ALL_RELEASES, PAGE_TYPES } from '@app-consts';
import { collapsibleCardAnimation } from '@app-generic/animations';
import { EnumErrorTypes } from '@app-models';
import { Actions, Model, State } from '@app-ngrx-domains';
import { ApiService } from '@app-services';
import { ValidatorsEx } from '@app-utilities';
import { Store } from '@ngrx/store';

@Component({
  animations: [ collapsibleCardAnimation ],
  selector: 'app-request-access',
  templateUrl: './request-access.component.html'
})
export class RequestAccessComponent implements OnInit {
  ACTIVITIES_READ_ONLY_TEXT: string = 'Read only access';
  DONT_KNOW_TEXT: string = 'I don\'t know';
  PROGRAMS_ALL_TEXT: string = 'All';

  editedRequestIndex: number;
  isLoading: boolean = false;
  myRequests: Array<Model.AccessRequest>;
  personalInfoForm: FormGroup;
  removedRequestIndex: number;
  shouldShowEditRequestModal: boolean = false;
  shouldShowPersonalInfo: boolean;
  shouldShowRemoveRequestAlert: boolean = false;
  shouldShowSuccess: boolean;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.store.dispatch(Actions.Layout.clearHeader());
    this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));

    this.personalInfoForm = this.formBuilder.group({
      email: ['', [Validators.required, ValidatorsEx.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]]
    });

    this.initRequests();
  }

  get email(): string {
    return this.personalInfoForm.get('email').value;
  }

  get firstName(): string {
    return this.personalInfoForm.get('firstName').value;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get hasRequests(): boolean {
    return this.myRequests.length > 0;
  }

  get lastName(): string {
    return this.personalInfoForm.get('lastName').value;
  }

  addRequest(request: Model.AccessRequest): void {
    this.myRequests.push(request);
  }

  dismissRequestAlert(): void {
    this.removedRequestIndex = -1;
    this.shouldShowRemoveRequestAlert = false;
  }

  edit(index: number): void {
    this.editedRequestIndex = index;
    this.shouldShowEditRequestModal = true;
  }

  editRequest(request: Model.AccessRequest): void {
    this.myRequests[this.editedRequestIndex] = request;
    this.shouldShowEditRequestModal = false;
  }

  getEmployerName(index: number): string {
    const request: Model.AccessRequest = this.myRequests[index];
    return request.employer ? request.employer.label : request.newEmployer.name;
  }

  initRequests(): void {
    this.myRequests = [];
    this.shouldShowPersonalInfo = false;
    this.shouldShowSuccess = false;
  }

  submitPersonalInfo(): void {
    const getActivities = (request: Model.AccessRequest): string => {
      if (request.activitiesDontKnow) {
        return this.DONT_KNOW_TEXT;
      } else if (request.activitiesReadOnly) {
        return this.ACTIVITIES_READ_ONLY_TEXT;
      } else {
        return request.activities.join(', ');
      }
    };
    const getPrograms = (request: Model.AccessRequest): string => {
      if (request.programsDontKnow) {
        return this.DONT_KNOW_TEXT;
      } else if (request.programsAll) {
        return this.PROGRAMS_ALL_TEXT;
      } else {
        return request.programs.join(', ');
      }
    };

    let message = `${this.fullName} `;
    message += `<${this.email}>`;
    this.myRequests.forEach((request: Model.AccessRequest): void => {
      if (request.employer) {
        message += ` | Employer: ${request.employer.label}`;
      } else {
        message += ` | Employer: ${request.newEmployer.name}, `;
        message += `Address Line 1: ${request.newEmployer.addressLine1}, `;
        message += request.newEmployer.addressLine2 ? `Address Line 2: ${request.newEmployer.addressLine2}, ` : '';
        message += `City: ${request.newEmployer.city}, `;
        message += `Zip: ${request.newEmployer.zip}`;
      }
      message += ` - Programs: ${getPrograms(request)} `;
      if (!request.programsDontKnow && !request.programsAll) {
        message += `(${getActivities(request)})`;
      }
      message += request.additionalInfo ? ` - Additional Information: ${request.additionalInfo}` : '';
    });

    this.isLoading = true;
    this.apiService.sendHelpDeskEmail({
      client_version: ALL_RELEASES[0].version,
      email: this.email,
      message,
      name: this.fullName,
      program: 0,
      type: 'Request access to NOVA',
      user_agent: navigator.userAgent
    }).subscribe((res: any): void => {
        this.isLoading = false;
        this.shouldShowSuccess = true;
      }, (raw: Error): void => this.store.dispatch(
        Actions.App.setError({
          location: this.constructor.name,
          raw,
          show: true,
          type: EnumErrorTypes.api
        })
      )
    );
  }

  remove(index: number): void {
    this.shouldShowRemoveRequestAlert = true;
    this.removedRequestIndex = index;
  }

  removeRequestAlert(): void {
    if (this.removedRequestIndex > -1) {
       this.myRequests.splice(this.removedRequestIndex, 1);
    }
    if (!this.hasRequests && this.shouldShowPersonalInfo) {
      this.shouldShowPersonalInfo = false;
    }
    this.dismissRequestAlert();
  }

  startNewRequest(): void {
    this.initRequests();
    this.personalInfoForm.reset();
  }
}
