import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { METRIC_GROUPS, PROGRAM_KEYS, SUCCESS_GOAL_GROUPS } from '@app-consts';
import { ProgramService } from '@app-services';
import { ValidatorsEx } from '@app/core/utilities';
import { collapsibleCardAnimation } from '@app-generic/animations';
import { ActivatedRoute } from '@angular/router';
import { PERKINS_1C_2025 } from '@app/Perkins/Perkins-1C/consts';
import { CAEP_V2_METRICS_YEAR } from '@app/AEBG/consts';

@Component({
  selector: 'project-goals-metrics-inline',
  templateUrl: './goals-metrics-inline.component.html',
  animations: [collapsibleCardAnimation]
})
export class GoalsAndMetricsInlineComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isPreview = false;
  @Input() canEdit = false;
  @Input() firstTouch = true;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  collectPerformanceOutcomes = false;
  form: FormGroup;
  successGoals: Array<Model.SuccessGoal> = [];
  metricsOptions: Array<{ metricName: string, options: Array<Model.SelectOption> }> = [];
  metricsOptionsByArea: { [areaName: string]: Array<Model.SelectOption> } = {};
  selectedMetrics: Array<number>;
  outcomeOptions: Array<Model.SelectOption>;

  proposal: any;
  program: Model.Fund;
  parentProgram: Model.Fund;
  parentEffortAreas: Array<Model.EffortArea>;
  threeYearPlan: Model.EAThreeYearPlan;
  isCAEP: boolean;
  isCAEPv2: boolean;
  isPerkins1Cv2: boolean;
  isSWP2030: boolean;

  public labels: { [name: string]: string} = {};
  public helpText: { [name: string]: string} = {};
  public showUnselectAlert = false;
  public goalToDelete: Model.SuccessGoal;
  public requiredGoals: Array<string>;

  private initialized: boolean = false;
  private creatingGoals: Array<number> = [];
  private visionGoals: Array<Model.EAVisionGoal> = [];
  private destroy$: Subject<boolean> = new Subject();
  private metricDefs: Array<Model.MetricDefinition>;

  constructor(
    private route: ActivatedRoute,
    private programService: ProgramService,
    private _fb: FormBuilder,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (this.guidance && this.guidance.isTemplate) {
      this.setupAsTemplate();
      return;
    }

    combineLatest([
      this.store.select(Queries.CurrentProposal.get)
    ])
      .pipe(
        withLatestFrom(
          this.store.select(Queries.LookupTables.getSuccessGoals),
          this.store.select(Queries.LookupTables.getMetricsDefinitions)
        ),
        filter(([[p], s_g, m]) => p && p.id && !!m),
        takeUntil(this.destroy$)
      )
      .subscribe(([[proposal], successGoals, metricDefs]) => {
        this.proposal = proposal;
        this.program = this.proposal.funds[0];
        this.isCAEP = this.program.key === PROGRAM_KEYS.CAEP;
        this.requiredGoals = this.isCAEP ? ['Student Barriers'] : [];

        if (this.isCAEP) {
          const durationId = Number(this.route.snapshot.params['durationId']);
          this.threeYearPlan = proposal.three_year_plans.find(plan => plan.duration_id === durationId);
          this.parentEffortAreas = (this.threeYearPlan ? [this.threeYearPlan] : undefined);
          this.isCAEPv2 = this.threeYearPlan.duration_id >= CAEP_V2_METRICS_YEAR;
        }
        const p = this.threeYearPlan || this.proposal;
        this.visionGoals = p.vision_goals || [];

        if (!!this.creatingGoals.length) {
          this.creatingGoals = this.creatingGoals.filter(goalId => !this.getVisionGoal(goalId));
        }

        // initialize one time set up code.
        this.initialize(successGoals, metricDefs);
      });
  }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initialize(successGoals: Array<Model.SuccessGoal>, metric_defs: Array<any>) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    this.parentProgram = this.programService.getParentProgramById(this.program.id);
    const isPerkins1C = this.program.key === PROGRAM_KEYS.PERKINS_1C;
    this.isPerkins1Cv2 = isPerkins1C && this.proposal.duration_id >= PERKINS_1C_2025;
    this.collectPerformanceOutcomes = this.parentProgram.key === PROGRAM_KEYS.EWD || this.isPerkins1Cv2;
    const isSWPv2 = [PROGRAM_KEYS.SWPL_v2, PROGRAM_KEYS.SWPR_v2, PROGRAM_KEYS.SWPL_v3, PROGRAM_KEYS.SWPR_v3].includes(this.program.key);

    let successGoalType = SUCCESS_GOAL_GROUPS.DEFAULT;
    if (this.isCAEPv2) {
      successGoalType = SUCCESS_GOAL_GROUPS.CAEP_v2;
    } else if (Object.values(SUCCESS_GOAL_GROUPS).includes(this.program.key) && (!isPerkins1C || this.isPerkins1Cv2)) {
      successGoalType = this.program.key;
    } else if (isSWPv2 && this.proposal.duration_id >= 2025) {
      this.isSWP2030 = true;
      successGoalType = SUCCESS_GOAL_GROUPS.VISION_2030;
    }

    this.successGoals = successGoals.filter(goal => goal.group === successGoalType);

    if (isSWPv2) {
      this.metricDefs = metric_defs.filter(m => m.group === METRIC_GROUPS.SWP && m.area === 'swp-v2');
      this.metricsOptions.push({ metricName: 'success_metrics', options: this.getMetricOptions() });
    } else if (this.isRCM) {
      this.metricDefs = metric_defs.filter(m => m.group === METRIC_GROUPS.DEFAULT);
      this.metricsOptions.push({ metricName: 'success_metrics', options: this.getMetricOptions() });
      this.metricDefs = metric_defs.filter(m => m.group === METRIC_GROUPS.SWP && m.area === 'swp-v2');
      this.metricsOptions.push({ metricName: 'swp_metrics', options: this.getMetricOptions() });
    } else if (this.isPerkins1Cv2) {
      this.metricDefs = metric_defs.filter(m => m.group === PROGRAM_KEYS.PERKINS_1C && m.is_outcome);
    } else if (this.isCAEPv2) {
      this.metricDefs = metric_defs.filter(m => [METRIC_GROUPS.CAEP_v2, METRIC_GROUPS.CAEP_PROGRAMS_v2].includes(m.group));
      this.metricsOptions.push({ metricName: 'success_metrics', options: this.getMetricOptions() });
      this.setMetricOptionsByArea();
    } else {
      const metricGroup = Object.values(METRIC_GROUPS).includes(this.parentProgram.key) ? this.parentProgram.key : METRIC_GROUPS.DEFAULT;
      this.metricDefs = metric_defs.filter(m => m.group === metricGroup);
      this.metricsOptions.push({ metricName: 'success_metrics', options: this.getMetricOptions() });
      this.setMetricOptionsByArea();
    }

    this.outcomeOptions = this.metricDefs.filter(m => m.is_outcome && (this.collectPerformanceOutcomes ? m.area === this.program.key : true))
      .sort((a, b) => a.sequence_number < b.sequence_number ? -1 : 1)
      .map(o => ({ label: `${o.uniq} ${o.name}`, value: o.id }));

    this.fillHelpTextAndLabels(this.program.key, this.program.parent_key);

    this.form = this._fb.group(this.successGoals.reduce((forms, goal) => {
      const visionGoal = this.getVisionGoal(goal.id);
      const metrics = visionGoal ? visionGoal.success_metrics : [];
      const outcomes = visionGoal && this.collectPerformanceOutcomes ? visionGoal.performance_outcomes : [];

      const groupObj = {
        selected: [visionGoal && visionGoal.selected],
        metrics: this._fb.group({
          success_metrics: [metrics.map(metric => metric.value)],
          swp_metrics: this.isRCM ? [metrics.map(metric => metric.value)] : undefined
        })
      };

      if (this.collectPerformanceOutcomes) {
        if (!this.isPerkins1Cv2) {
          groupObj['performance_outcomes'] = [outcomes.map(outcome => outcome.value), [ValidatorsEx.requiredSelection]];
        } else {
          groupObj['performance_outcomes'] = this._fb.group(goal.child_outcomes.reduce((ctrls, metricDef) => {
            const outcome = outcomes.find(o => o.value === metricDef.id);
            const outcomeCtrl = ctrls[`outcome_${metricDef.id}`] = this._fb.group({
              outcome_selected: [{ value: outcome, disabled: metricDef.child_metrics?.length }]
            });

            // Listen to outcome changes
            outcomeCtrl.get('outcome_selected').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
              const goalCtrl = this.form.get(goal.name);
              const goalSelectCtrl = goalCtrl.get('selected');
              const childCtrls = outcomeCtrl.get('child_outcomes') as FormGroup;
              // If outcome is selected, goal needs to be selected
              if (value) {
                if (!goalSelectCtrl.value) {
                  // Set parent to true but don't trigger the valueChange listener if goal EA does not exist
                  // because this change will create all necessary attrs/EAs in one call further down in outcomeSelectChanged
                  const emitEvent = this.getVisionGoal(goal.id) != null;
                  goalSelectCtrl.setValue(true, { emitEvent });
                }
              } else if (childCtrls != null) {
                // If outcome is deselected, child outcomes need to be deselected
                Object.keys(childCtrls.controls).map(c => childCtrls.get(c)).forEach(ctrl => {
                  if (ctrl.value) {
                    ctrl.setValue(false);
                  }
                });
              }

              // Upsert attributes (and create goal EA if necessary)
              this.outcomeSelectChanged(goal, metricDef, value);

              if (childCtrls != null) {
                childCtrls.updateValueAndValidity();
              }
              goalCtrl.updateValueAndValidity();
            });

            // If outcome has children, create controls for it
            if (metricDef.child_metrics.length) {
              const childOutcomesCtrl = this._fb.group(metricDef.child_metrics.reduce((childCtrls, childMetricDef) => {
                const childOutcome = outcomes.find(o => o.value === childMetricDef.id);
                const childCtrl = childCtrls[`outcome_${childMetricDef.id}`]= new FormControl(!!childOutcome);
                const selectedOutcomeCtrl = outcomeCtrl.get('outcome_selected');
                if (childOutcome && !selectedOutcomeCtrl.value) {
                  selectedOutcomeCtrl.setValue(true, { emitEvent: false, selfOnly: true });
                }

                // Listen to child outcome changes
                childCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
                  if (value) {
                    const goalSelectCtrl = this.form.get(goal.name).get('selected');
                    const emitEvent = this.getVisionGoal(goal.id) != null;

                    // Set parent to true but don't trigger the valueChange listener if goal EA does not exist
                    // because this change will create all necessary attrs/EAs in one call further down in outcomeSelectChanged
                    if (!selectedOutcomeCtrl.value) {
                      // Never update parent outcome attrs, we only need the child valuse
                      selectedOutcomeCtrl.setValue(true, { emitEvent: false, selfOnly: true });

                      if (!emitEvent && !goalSelectCtrl.value) {
                        goalSelectCtrl.setValue(true, { emitEvent });
                      }
                    }
                  } else {
                    const childOutcomesValue = childOutcomesCtrl.value;
                    if (Object.keys(childOutcomesValue).every(c => !childOutcomesValue[c].value)) {
                      selectedOutcomeCtrl.setValue(false, { emitEvent: false, selfOnly: true });
                    }
                  }

                  // Upsert attributes (and create goal EA if necessary)
                  this.outcomeSelectChanged(goal, childMetricDef, value);
                });
                return childCtrls;
              }, {}), { validators: this.childOutcomeSelectionValidator(outcomeCtrl.get('outcome_selected')) });
              outcomeCtrl.addControl('child_outcomes', childOutcomesCtrl);
            }

            return ctrls;
          }, {}), { validators: this.outcomeSelectionValidator(goal.name) });
        }
      }

      const formCtrl = forms[goal.name] = this._fb.group(groupObj, { validators: this.metricSelectionValidator });
      if (this.isPerkins1Cv2) {
        formCtrl.get('selected').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
          const outcomeCtrls = formCtrl.get('performance_outcomes') as FormGroup;
          if (!value) {
            // If goal is deselected, all outcome and child outcomes need to be deselected
            Object.keys(outcomeCtrls.controls).map(c => outcomeCtrls.get(c)).forEach(ctrl => {
              const selectCtrl = ctrl.get('outcome_selected');
              if (selectCtrl.value) {
                selectCtrl.setValue(false);
              }
            });
          }
          // Upsert EA or attributes
          this.toggleSelected(goal, value);
          outcomeCtrls.updateValueAndValidity();
        });
      }
      return forms;
    }, {}));

    if (this.isPerkins1Cv2) {
      // Each goal needs at least 1 outcome selected
      this.form.addValidators(this.goalSelectionValidator);
    }

    // To set required goals, the form must be initialized
    this.successGoals.forEach(goal => {
      if (this.requiredGoals.includes(goal.name)) {
        const control = this.form.get(goal.name).get('selected');
        control.setValue(true);
        this.toggleSelected(goal);
      }
    });
  }

  metricSelectionValidator = (group: FormGroup) => {
    const { selected, metrics } = group.value;
    if (selected) {
      if (!Object.values(metrics).some((metricSelections: Array<any>) => metricSelections && metricSelections.length)) {
        group.get('metrics').setErrors({
          validationError: 'At least one metric must be selected'
        });
      }
    }
  };

  goalSelectionValidator = (group: FormGroup) => {
    const value = group.value;
    if (!Object.keys(value).some(goal => value[goal].selected)) {
      return {
        requiredCheck: {
          message: 'At least one goal must be selected'
        }
      }
    }
  };

  outcomeSelectionValidator(goal: string) {
    return (group: FormGroup) => {
      if (this.form) {
        const goalSelected = this.form.get(goal).get('selected').value;
        const value = group.getRawValue();
        if (goalSelected && !Object.keys(value).some(outcome => value[outcome].outcome_selected)) {
          return {
            requiredCheck: {
              message: 'At least one outcome must be selected'
            }
          }
        }
      }
    };
  }

  childOutcomeSelectionValidator(parentOutcomeCtrl: AbstractControl) {
    return (group: FormGroup) => {
      if (this.form) {
        const parentSelected = parentOutcomeCtrl.value;
        const value = group.value;
        if (parentSelected && !Object.keys(value).some(outcome => value[outcome])) {
          return {
            requiredCheck: {
              message: 'At least one child outcome must be selected'
            }
          }
        }
      }
    };
  }

  setMetricOptionsByArea() {
    // Refines options for CAEP by area
    this.metricsOptionsByArea = {};
    this.successGoals.forEach(goal => {
      this.metricsOptionsByArea[goal.name] = this.getMetricOptions(goal.name);
    });
  }

  private getMetricOptions(area?: string): Array<Model.SelectOption> {
    return this.metricDefs.filter(m => !m.is_outcome)
      .filter(m => area ? m.area === area : m)
      .map(m => ({ value: m.id, label: m.name }))
      .sort((a, b) => a.label < b.label ? -1 : 1);
  }

  private setupAsTemplate() {
    this.successGoals = [];
    const program = this.programService.getProgramById(this.guidance.programId);
    this.fillHelpTextAndLabels(program.key, program.parent_key);
  }

  private fillHelpTextAndLabels(key: string, parentKey: string) {
    // assign default help text
    this.helpText = {
      ['card']: 'At least one Vision for Success goal is required for this application. For each Vision for Success goal selected, select all relevant Student Success Metrics.',
    };

    // assign default labels
    this.labels = {
      ['card']: 'Vision for Success Goals and Student Success Metrics',
      ['card_preview']: 'Goals & Metrics: Vision for Success Goals and Student Success Metrics',
      ['success_metrics']: 'Student Success Metrics',
      ['swp_metrics']: 'SWP Metrics',
    };

    // help text & label overrides
    switch (parentKey) {

      case PROGRAM_KEYS.EWD: {
        this.helpText.card = 'At least one Vision for Success goal is required for this application. For each Vision for Success goal selected, select all relevant Student Success Metrics and all relevant Performance Outcomes. At least one of each is required.';
        break;
      }

      case PROGRAM_KEYS.SWP_R:
      case PROGRAM_KEYS.SWP_L: {
        if (this.isSWP2030) {
          this.labels.card = 'Vision 2030 Outcomes and SWP Metrics';
          this.helpText.card = 'At least one Vision 2030 outcome is required for this plan. For each Vision 2030 outcome selected, select all relevant Strong Workforce Program Metrics.'
          this.labels.card_preview = 'Metrics: Vision 2030 Outcomes and SWP Metrics';
        } else {
          this.helpText.card = 'At least one Vision for Success goal is required for this plan. For each Vision for Success goal selected, select all relevant Strong Workforce Program Metrics.';
          this.labels.card = 'Vision for Success Goals and SWP Metrics';
          this.labels.card_preview = 'Metrics: Vision for Success Goals and SWP Metrics';
        }
        this.labels.success_metrics = 'SWP Metrics';
        break;
      }

      case PROGRAM_KEYS.PERKINS: {
        if (this.isPerkins1Cv2) {
          this.helpText.card = '';  // Help text is in Guidance card instead
          this.labels.card = 'Vision 2030';
          break;
        }
      }

      case null: {
        switch (key) {
          case PROGRAM_KEYS.CAEP: {
            this.helpText.card = `
              <p>
                Each <strong>Consortium</strong> is required to track the metric types of: Number of Adults Served and Student Barriers.
                An additional two metrics are required to be tracked by all <strong>Members</strong>: Adults Served who Became Participants and Percent of Available Funds Spent.
              </p>
              <p>
                <strong>Student Barriers</strong>: Of the four student barriers listed (English Language Learner, Low Literacy, Low Income, Long Term Unemployed), select at least one.
                Additional barriers may be selected.
              </p>
              <p>
                <strong>Optional Metrics</strong>: Consortium members may choose from a list of ten optional metrics (see drop down menu). If a member chooses an optional metric,
                that metric will appear for all members in the consortium. If a member does not have students for a particular metric, they will enter 0 (if not planning to address)
                or set a target (if planning to address). If a member has students for a particular target but do not plan to grow their program, they will enter the same number as the actual.
                Unfunded members will follow the same processes above as funded members.
              </p>
            `;
            this.labels.card = 'CAEP Barriers & Metrics';
            this.labels.card_preview = 'Metrics: CAEP Barriers & Metrics';
            this.labels.success_metrics = 'Adult Ed Metrics';
          }
        }
      }
    }
  }

  public get hasVisionGoal(): boolean {
    if (this.visionGoals) {
      const selectedGoals = this.visionGoals.filter(goal => goal.selected);
      return !!selectedGoals.length;
    } else {
      return false;
    }
  }

  getVisionGoal(goalId: number) {
    return this.visionGoals.find(goal => goal.success_goal && goal.success_goal.id === goalId);
  }

  formatGoalLabel(goal: Model.SuccessGoal) {
    return `<span class="h4 bold">${goal.name}${goal.description ?
      `${this.isPerkins1Cv2 ? '</span><br>' : ':</span>' } ` + goal.description : '</span>'}`;
  }

  goalSelectChanged(goal: Model.SuccessGoal, ignoreChanges?: boolean) {
    if (ignoreChanges) {
      // For Perkins 1C v2, we only want to watch changes programmatically.
      return;
    }
    const selected = this.form.get(goal.name).get('selected').value;
    if (!selected && !this.isPerkins1Cv2) {
      this.toggleShowUnselectAlert(goal);
    } else {
      this.toggleSelected(goal);
    }
  }

  outcomeSelectChanged(goal: Model.SuccessGoal, outcome: Model.MetricDefinition, selected: boolean) {
    if (this.disableGoalSelection(goal.id)) {
      // If still in the process of creating goal, set formControl back to the selected state
      this.form.get(goal.name).get('selected').setValue(true, { emitEvent: false });
      return;
    }

    if (selected) {
      const visionGoal = this.getVisionGoal(goal.id);
      if (visionGoal) {
        this.addAttribute('performance_outcomes', goal.id, outcome.id);
      } else {
        // If this is a child metric, include the parent metric when creating the goal EA
        const performanceOutcomes = [outcome.id];
        // const parentMetricId = outcome.parent_metric_id;
        // if (parentMetricId != null) {
        //   performanceOutcomes.push(parentMetricId);
        // }
        this.createGoalEA(goal.id, { selected: true, performance_outcomes: performanceOutcomes });
      }
    } else {
      this.removeAttribute('performance_outcomes', goal.id, outcome.id);
    }
  }

  toggleShowUnselectAlert(goal: Model.SuccessGoal) {
    if (this.goalToDelete && !goal) {
      this.form.get(this.goalToDelete.name).get('selected').setValue(true);
    }
    this.goalToDelete = goal;
    this.showUnselectAlert = !!this.goalToDelete;
  }

  toggleSelected(goal: Model.SuccessGoal, selectedValue?: boolean) {
    if (this.disableGoalSelection(goal.id)) {
      // Set formControl back to the selected state
      this.form.get(goal.name).get('selected').setValue(true, { emitEvent: false });
      return;
    }

    const ea = this.getVisionGoal(goal.id);
    const selected = selectedValue != null ? selectedValue : this.form.get(goal.name).value['selected'];

    if (ea) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ ea, key: 'selected', value: selected, parentEffortAreas: this.parentEffortAreas }));
    } else if (selected) {
      this.createGoalEA(goal.id, { selected });
    }

    if (this.showUnselectAlert) {
      this.showUnselectAlert = false;
    }
  }

  createGoalEA(goalId: number, attrs: any) {
    this.creatingGoals.push(goalId);

    // Create a new effort-area
    const payload = {
      effort_area_type: 'vision_goals',
      parent_effort_area_id: this.threeYearPlan ? this.threeYearPlan.id : undefined,
      parent_proposal_id: this.proposal.id,
      success_goal: goalId,
      ...attrs
    };
    this.store.dispatch(Actions.CurrentProposal.createEffortArea({ ea: payload, parentEffortAreas: this.parentEffortAreas  }));
  }

  disableGoalSelection(goalId: number) {
    return this.creatingGoals.find(goal => goal === goalId);
  }

  requireMetricsForGoal(goalId: number) {
    const visionGoal = this.getVisionGoal(goalId);
    return !this.isPerkins1Cv2 && visionGoal && visionGoal.selected;
  }

  addAttribute(attributeName: string, goalId: number, value: any | string) {
    const ea = this.getVisionGoal(goalId);
    if (ea) {
      this.store.dispatch(Actions.CurrentProposal.addMultiAttribute({ ea, key: attributeName, value, parentEffortAreas: this.parentEffortAreas }));
    }
  }

  removeAttribute(attributeName: string, goalId: number, value: number) {
    const ea = this.getVisionGoal(goalId);
    if (ea) {
      this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ ea, key: attributeName, value, parentEffortAreas: this.parentEffortAreas }));
    }
  }

  getMetricsFormControl(goalName: string) {
    if (this.form) {
      return this.form.controls[goalName].get('metrics');
    }
  }

  formatOutcomeLabel(outcome: Model.MetricDefinition, child?: boolean) {
    return `<span class="bold">${outcome.name}</span>
      ${ child ? ': ' : '<br>' }${outcome.description}`;
  }

  private get isRCM(): boolean {
    return this.program.parent_key === PROGRAM_KEYS.RCM;
  }
}
