import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef, AfterViewInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { ApiService, LookupService, PermissionsService, ProgramService } from '@app-services';
import { Fund, ProposalBase as Proposal } from '@app-models';
import { AREAS, PROGRAM_KEYS, WORKFLOW_STEPS, PROPOSAL_TYPES, TASK_TYPES } from '@app-consts';
import { groupBy, get } from 'lodash';
import { allSectorsOption } from '@app/shared.project/components/workplan-activities/activities/activities-card.component';
import { CurrencyPipe } from '@angular/common';
import { SAVE_MODIFICATIONS_CHECKBOX_TEXT } from '@app/shared.project/consts';

@Component({
  selector: 'app-budget-v2',
  templateUrl: './budget.component.html',
})
export class BudgetV2Component implements OnInit, OnDestroy, AfterViewInit {
  @Input() isPreview = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;
  @Output() budgetPreviewAlert: EventEmitter<TemplateRef<any>> = new EventEmitter();
  @ViewChild('previewAlert', { static: true }) previewAlert: TemplateRef<any>;
  @ViewChild('budgetSummaryTable', { static: true }) budgetSummaryTable: TemplateRef<any>;

  public initialized$ = new BehaviorSubject<boolean>(false);
  public canEdit: boolean = false;
  public canModify: boolean = false;
  public firstTouch: boolean = false;
  public isPlan: boolean = false;
  public isCertified: boolean = false;

  public proposal: Model.SWPProposal;
  public institutions: Array<Model.Institution>;
  public yearOptions: Array<Model.Duration> = [];
  public budgetsByInstitution: { [institutionId: number]: Array<Model.EAPlanBudgetItem> };
  public objectCodeOptions: Array<Model.SelectOption>;
  public activityOptions: Array<Model.SelectOption>;
  public yearForm: FormGroup;

  public allSectorsLimit: number;
  public allSectorsPercentage: number;
  public allSectorsAmount: number;
  public totalAmount: number;

  private destroy$: Subject<boolean> = new Subject();
  private currentWorkflowStep = WORKFLOW_STEPS.BUDGET;
  private parentProgram: Model.Fund;
  private parentKey: string;

  public isModifying = false;
  public modificationMaxPercent = .1;
  public modificationMaxAmount: number;
  public modificationRemaining: number;
  public modifiableObjectCodesByInstitution: { [institutionId: string]: Array<Model.SelectOption> } = {};
  public showSaveModificationsModal = false;
  public showModificationsDiscardAlert = false;
  public budgetSummaryEntries: Array<Model.BudgetSummaryEntry>;
  public saveModificationsForm: FormGroup;
  public saveModificationCheckboxText = SAVE_MODIFICATIONS_CHECKBOX_TEXT;

  // SWP only
  public isRegional: boolean;
  public isDecertified: boolean;
  public availableFunding: {
    requestedAmount: number;
    amountRemaining: number;
    isAvailable: boolean;
  };
  public amountRemaining: {
    label: string;
    amount: number;
  };

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private lookupService: LookupService,
    private programService: ProgramService,
    private _fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private apiService: ApiService
  ) {
  }

  ngOnInit() {
    if (!!this.guidance) {
      // in template mode...prepare to show as guidance template.
      this.setupAsGuidanceTemplate();
      return;
    }

    this.store.select(Queries.CurrentProposal.get).pipe(
      filter(p => p && p.id),
      withLatestFrom(
        this.store.select(Queries.LookupTables.getObjectCodesMS),
      ),
      takeUntil(this.destroy$),
    ).subscribe(([proposal, objectCodes]) => {
      this.proposal = proposal;
      this.isPlan = Proposal.isPlan(proposal);
      this.isCertified = Proposal.projectIsCertified(this.proposal);

      this.initialize(objectCodes);

      this.budgetsByInstitution = groupBy(this.proposal.plan_budget_items, 'institution_id');

      this.calculateTotals();
      this.updateAvailableFunding();
    });
  }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async initialize(objectCodes) {
    if (this.initialized$.value) {
      // all's been setup.
      return;
    }

    try {
      this.store.dispatch(Actions.Layout.showBusySpinner(true));

      const program = this.proposal.funds[0];
      this.parentKey = program.parent_key;
      this.parentProgram = this.programService.getParentProgramByKey(program.parent_key);

      if (!this.guidance) {
        // set up guidance
        this.guidance = {
          programId: program.id,
          proposalType: PROPOSAL_TYPES.APPLICATION,
          workflowName: program.key,
          stepName: this.currentWorkflowStep,
        }
      }

      if (this.isSWP) {
        this.isDecertified = this.proposal.tasks.some(t => t.task_type === TASK_TYPES.SWP_PROPOSAL_APPROVE_V2 && t.completed);
        this.isRegional = this.parentKey === PROGRAM_KEYS.SWP_R;
      }

      const leadInst = this.proposal.lead_institution;
      this.institutions = this.isSWP
        ? this.proposal.institutions.filter((inst) => {
            // filter out Employer Partners
            return !inst.is_lead && !this.proposal['swp_collaborative_partners'].find(partner => partner.institution_id === inst.id)
          }).sort((a, b) => a.name > b.name ? 1 : -1)
        : [leadInst];
      if (this.isSWP && leadInst) {
        this.institutions.unshift(leadInst);
      }

      this.objectCodeOptions = objectCodes;
      this.activityOptions = this.proposal.workplan_activities
        .filter(activity => activity.id > 0) // filter out temporary activities
        .map(activity => ({ label: activity.title || 'Unnamed Activity', value: activity.id }));

      if (!this.isPreview) {
        this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

        this.canEdit = await this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot).toPromise();
        this.setupModification();

        this.firstTouch = false;
      }

      this.allSectorsLimit = this.parentProgram.program_settings && this.parentProgram.program_settings.budget_limit;

      const plan_length = this.proposal.plan_length || 1;

      for (let year = this.proposal.duration_id; year < this.proposal.duration_id + plan_length; year++) {
        this.yearOptions.push(this.lookupService.getYear(year));
      }

      if (this.yearOptions.length) {
        this.yearForm = this._fb.group({ selectedYear: [this.yearOptions[0].id] });
      }

    } finally {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.initialized$.next(true);
    }
  }

  updateAvailableFunding() {
    if (this.isSWP && this.proposal.approver_institution_id) {
      this.apiService.getAvailableFunding(this.proposal.id).subscribe(result => {
        this.availableFunding = result;

        this.amountRemaining = {
          label: `Available funds for your ${this.parentKey === PROGRAM_KEYS.SWP_R ? 'region' : 'district'}`,
          amount: result.amountRemaining
        }
      });
    }
  }

  filteredBudgetItems(id: number) {
    const budgetItems = this.budgetsByInstitution[id] || [];
    const selectedYear = this.selectedYearId;
    return this.isPreview ? budgetItems : budgetItems.filter(item => item.duration_id === selectedYear);
  }

  sortedBudgetItems() {
    const budgetsByExpenditure = groupBy(this.proposal.plan_budget_items, 'object_code_id');
    let sorted = [];

    // cache fund names to avoid redundant lookups
    const memo = {};
    Object.keys(budgetsByExpenditure).forEach((id) => {
      budgetsByExpenditure[id].sort((a, b) => {
        const fundA = a['fund_id'], fundB = b['fund_id'];
        let fundNameA, fundNameB;

        const getMemoFundName = (fundId) => {
          if (memo[fundId]) {
            return memo[fundId];
          } else {
            const lookup = this.getFundName(fundId);
            memo[fundId] = lookup;
            return lookup;
          }
        }
        fundNameA = getMemoFundName(fundA);
        fundNameB = getMemoFundName(fundB);
        return fundNameA.localeCompare(fundNameB);
      });
    });

    Object.keys(budgetsByExpenditure).forEach(id => {
      const budgetItems = budgetsByExpenditure[id];
      sorted = sorted.concat(budgetItems);
    });

    return sorted;
  }

  filteredAndSortedBudgetItems(id: number, includeRevised: boolean = false) {
    const filteredBudgets = this.filteredBudgetItems(id);
    const budgetsByExpenditure = {};

    // Group the budgets together by expenditure and year
    filteredBudgets.forEach(budget => {
      const object_code_id = budget.object_code_id;
      const duration_id = budget.duration_id;

      if (budgetsByExpenditure[object_code_id] == null) {
        budgetsByExpenditure[object_code_id] = {};
      }

      if (budgetsByExpenditure[object_code_id][duration_id] == null) {
        budgetsByExpenditure[object_code_id][duration_id] = [];
      }

      const isRevised = !!budget.revised_amount || budget.revised_amount === 0;
      const hasAmount = !!budget.direct_amount || budget.direct_amount === 0;
      if (!includeRevised && isRevised && !hasAmount) {
        return;
      }

      budgetsByExpenditure[object_code_id][duration_id].push(budget);
    });

    let sorted = [];
    const activities = {};

    // 1. Sort the budgets by expenditure
    Object.keys(budgetsByExpenditure).sort().forEach(object_code_id => {
      const budgetsByYear = budgetsByExpenditure[object_code_id];

      // 2. Sort the budgets by year
      Object.keys(budgetsByYear).sort().forEach(duration_id => {

        // 3. Sort the budgets by activities
        budgetsByYear[duration_id].sort((a, b) => {
          const activityA = a['related_activities'], activityB = b['related_activities'];

          let activityNameA, activityNameB;

          const getActivityName = (relatedActivities) => {
            const activityId = relatedActivities.length ? relatedActivities[0].value : undefined;
            if (activities[activityId]) {
              return activities[activityId];
            } else {
              const activity = this.getActivity(activityId);
              activities[activityId] = activity;
              return activity;
            }
          }
          activityNameA = getActivityName(activityA);
          activityNameB = getActivityName(activityB);

          return activityNameA.localeCompare(activityNameB);
        });

        sorted = sorted.concat(budgetsByYear[duration_id]);
      });
    });

    return sorted;
  }

  getActivity(activityId: number) {
    const activity = this.activityOptions.find(option => option.value === activityId);
    return activity && activity.label || 'Unknown Activity';
  }

  calculateTotals() {
    const allSectorsActivities = this.proposal.workplan_activities
      .filter(activity => activity.priority_sector === allSectorsOption.value)
      .map(activity => activity.id);

    let allSectorsAmount = 0, totalAmount = 0;

    this.proposal.plan_budget_items.forEach(budgetItem => {
      const activityId = get(budgetItem, 'related_activities[0].value');
      const budgetAmount = budgetItem.direct_amount || 0;
      if (allSectorsActivities.includes(activityId)) {
        allSectorsAmount += budgetAmount;
      }
      totalAmount += budgetAmount;
    });

    this.allSectorsAmount = allSectorsAmount;
    this.totalAmount = totalAmount;
    this.allSectorsPercentage = (this.allSectorsAmount && this.totalAmount)
      ? Math.round((100 * this.allSectorsAmount) / this.totalAmount)
      : 0;

    if (this.isPreview) {
      this.budgetPreviewAlert.emit(this.showAllSectorsAlert ? this.previewAlert : null);
    }

    this.calculateModifications();
  }

  shouldDisableCloning(id: number): boolean {
    const budgetItems = this.budgetsByInstitution[id] || [];
    const previousYear = this.selectedYearId - 1;
    return !budgetItems.some(item => item.duration_id === previousYear);
  }

  copyPreviousBudgets(id: number, toYear: number) {
    const cloneOptions = [];

    (this.budgetsByInstitution[id] || []).forEach(budgetItem => {
      if (budgetItem.duration_id === toYear - 1) {
        const eaType = budgetItem.effort_area_type;
        const modifiable = this.isModifying && !!this.modifiableObjectCodesByInstitution[id].find((oc) => oc.value === budgetItem.object_code_id);
        const modifiableReplacers = [
          { key: 'direct_amount', value: undefined, effort_area_type: eaType },
          { key: 'revised_amount', value: budgetItem.revised_amount || budgetItem.direct_amount, effort_area_type: eaType },
        ];

        cloneOptions.push({
          effortAreaId: budgetItem.id,
          options: {
            replacers: [
              { key: 'duration_id', value: toYear, effort_area_type: eaType },
              ...(modifiable ? modifiableReplacers : [])
            ],
            targetModel: 'proposal'
          }
        });
      }
    });

    if (!!cloneOptions.length) {
      this.store.dispatch(Actions.CurrentProposal.cloneEffortAreas(cloneOptions));
    }
  }

  get showAllSectorsAlert() {
    return this.allSectorsPercentage > this.allSectorsLimit;
  }

  get selectedYearId() {
    return this.yearForm ? this.yearForm.get('selectedYear').value : undefined;
  }

  private setupAsGuidanceTemplate() {
    const program = this.programService.getProgramById(this.guidance.programId);
    this.parentKey = program.parent_key;
    this.initialized$.next(true);
  }

  get allowCloning() {
    return this.selectedYearId && (this.selectedYearId > this.yearOptions[0].id);
  }

  get isSWP(): boolean {
    return [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.parentKey);
  }

  get exceedsFunding(): boolean {
    return this.availableFunding && !this.availableFunding.isAvailable;
  }

  trackById(index: number, item: Model.Institution) {
    return item.id;
  }

  getFundName(id: number): string {
    const program = this.programService.getProgramById(id);
    return Fund.getShortestName(program);
  }


  // ---------------------------------------------------
  // Budget Modification
  // ---------------------------------------------------

  private setupModification() {
    this.canModify = this.isSWP && this.canEdit && this.isCertified;
    if (this.isCertified) {
      this.setModifiableObjectCodes();
      this.calculateModifications();
      this.saveModificationsForm = this._fb.group({ canSave: [undefined, [Validators.required]] });
      this.budgetSummaryEntries = this.getModificationBudgetSummary();
    }
  }

  private calculateModifications() {
    if (this.canModify) {
      this.modificationMaxAmount = Math.floor((this.totalAmount || 0) * this.modificationMaxPercent);
      this.modificationRemaining = this.modificationMaxAmount - this.totalModified;
    }
  }

  public get summaryTitle(): string {
    return this.isModifying ? 'Budget Modification' : 'Budget Summary';
  }

  private setModifiableObjectCodes() {
    // Object codes are modifiable if the institution has existing budget items for it
    const instIds = this.institutions.map(i => i.id);
    instIds.forEach((institutionId) => {
      this.modifiableObjectCodesByInstitution[institutionId] = [];
      const budgetsByObjectCode = groupBy(this.budgetsByInstitution[institutionId], 'object_code_id');

      // Indirect Costs not modifiable
      if (budgetsByObjectCode['1269']) {
        delete budgetsByObjectCode['1269'];
      }

      Object.keys(budgetsByObjectCode).forEach((key) => {
        const objectCodeTotal = budgetsByObjectCode[key].reduce((sum, budgetItem) => sum + (budgetItem.direct_amount || 0), 0);
        if (!!objectCodeTotal) {
          const objectCode = this.objectCodeOptions.find(o => o.value === Number(key));
          this.modifiableObjectCodesByInstitution[institutionId].push({ label: objectCode.label, value: Number(key)});
        }
      });
    });
  }

  public getModifiableObjectCodes(institutionId: number): Array<Model.SelectOption> {
    return this.modifiableObjectCodesByInstitution[institutionId];
  }

  public get totalModified() {
    if (!this.proposal) {
      return;
    }
    return this.proposal.plan_budget_items
      .filter((budgetItem) => budgetItem.revised_amount > (budgetItem.direct_amount || 0))
      .reduce((sum, budgetItem) => sum + (budgetItem.revised_amount - (budgetItem.direct_amount || 0)), (this.proposal.total_revised_amount || 0));
  }

  public get totalModifiedBudget() {
    return this.proposal.plan_budget_items.reduce((total, budgetItem) => {
      const amount = budgetItem.revised_amount || budgetItem.revised_amount === 0 ? budgetItem.revised_amount : budgetItem.direct_amount;
      return total + (amount || 0);
    }, 0);
  }

  public get modificationHelpText() {
    const remaining = this.modificationRemaining < 0 ? 0 : this.modificationRemaining;
    return `
      of ${this.currencyPipe.transform(this.modificationMaxAmount, 'USD', 'symbol', '1.0')}
      (${this.currencyPipe.transform(remaining, 'USD', 'symbol', '1.0')} Remaining)
    `;
  }

  public get hasUnsavedModifications(): boolean {
    const unsavedModifications = this.getModifiedBudgets();
    return this.isCertified && !!unsavedModifications.length;
  }

  public get modifiedBudgetItemsByInstitution() {
    const budgets = this.getModifiedBudgets();
    const groups = groupBy(budgets, 'institution_id');
    const result = [];
    Object.keys(groups).forEach(group => {
      result.push({ id: Number(group), budgets: groups[group] });
    })
    return result;
  }

  public get disableSaveModify(): boolean {
    return this.totalModifiedBudget !== this.totalAmount || this.modificationRemaining < 0 || this.saveModificationsForm.invalid;
  }

  public get modificationsUnsavedText() {
    return `There are unsaved Grant Funds Modifications. ${this.isModifying ? 'Outlined amounts in budget tables indicate unsaved amounts.' : ''}`;
  }

  public get modificationsModalErrorText() {
    if (this.modificationRemaining < 0) {
      return this.modificationLimitExceededText;
    } else if (this.totalModifiedBudget !== this.totalAmount) {
      return this.modificationTotalsAlertText;
    }
  }

  public get modificationLimitExceededText() {
    const exceeded = Math.abs(this.modificationRemaining);
    return `${this.currencyPipe.transform(exceeded, 'USD', 'symbol', '1.0')} Over Limit`;
  }

  public get modificationTotalsAlertText() {
    const difference = Math.abs(this.totalAmount - this.totalModifiedBudget);
    return `${this.currencyPipe.transform(difference, 'USD', 'symbol', '1.0')} ${this.totalAmount > this.totalModifiedBudget ? 'Under Budget' : 'Over Budget'}`;
  }

  private getModifiedBudgets() {
    if (!this.proposal) {
      return;
    }
    return this.proposal.plan_budget_items.filter((budgetItem) => budgetItem.revised_amount || budgetItem.revised_amount === 0);
  }

  public handleModify(event: { save: boolean, isModifying: boolean, cancel: boolean }) {
    if (event.save) {
      this.showSaveModificationsModal = true;
    } else {
      // Discard changes if there are any
      if (!event.cancel && this.isModifying && this.hasUnsavedModifications) {
        this.showModificationsDiscardAlert = true;
        return;
      }
    }

    this.isModifying = event.isModifying;
  }

  public updateBudgetModifications(save: boolean = true) {
    // Prevent hacks if user enables button via developer tools
    if (save && this.saveModificationsForm.invalid) {
      return;
    }

    const modifiedBudgets = this.getModifiedBudgets();
    const attributes = [];
    const deletes = [];

    // The revised_amount is cleared and, if saving, gets moved to direct_amount
    modifiedBudgets.forEach(budgetItem => {
      if (save) {
        if (budgetItem.revised_amount === 0) {
          deletes.push(budgetItem);
        } else {
          attributes.push({ key: 'direct_amount', value: budgetItem.revised_amount, ea: budgetItem });
          attributes.push({ key: 'revised_amount', value: undefined, ea: budgetItem });
        }
      } else {
        const hasAmount = !!budgetItem.direct_amount || budgetItem.direct_amount === 0;
        if (hasAmount) {
          // This budget item existed before modification, so keep it
          attributes.push({ key: 'revised_amount', value: undefined, ea: budgetItem });
        } else {
          // Discarding changes would leave both direct_amount & revised_amount null, so just delete the effort area
          deletes.push(budgetItem);
        }
      }
    });

    if (save) {
      // Update total_revised_amount
      attributes.push({ key: 'total_revised_amount', value: this.totalModified });
      this.updateForecasts();
    }

    if (!!attributes.length) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttributes(attributes));
    }

    if (!!deletes.length) {
      this.store.dispatch(Actions.CurrentProposal.deleteMultiEffortAreas({ eas: deletes }));
    }

    this.dismissModificationsModal();
    this.showModificationsDiscardAlert = false;
    this.isModifying = false;
  }

  public dismissModificationsModal() {
    this.showSaveModificationsModal = false;
    this.saveModificationsForm.reset();
  }

  updateForecasts() {
    const forecastsToDelete = [];

    // Forecasts whose budgets are being cleared out
    Object.keys(this.budgetsByInstitution).forEach((institutionId) => {
      const instBudgetsByDuration = groupBy(this.budgetsByInstitution[institutionId], 'duration_id');
      Object.keys(instBudgetsByDuration).forEach((durationId) => {
        const hasRevisions = instBudgetsByDuration[durationId].some((item) => !!item.revised_amount || item.revised_amount === 0);
        if (!hasRevisions) {
          return;
        }
        const total = instBudgetsByDuration[durationId].reduce((sum, item) => sum += item.direct_amount || item.revised_amount || 0, 0);
        if (total === 0) {
          const forecast = this.proposal.plan_expenditure_forecasts.find((f) => f.institution_id === Number(institutionId) && f.duration_id === Number(durationId));
          if (forecast) {
            forecastsToDelete.push(forecast);
          }
        }
      });
    });

    if (!forecastsToDelete.length) { return; }

    this.store.dispatch(Actions.CurrentProposal.deleteMultiEffortAreas({ eas: forecastsToDelete }));
  }

  private getModificationBudgetSummary(): Array<any> {
    return [
      {
        title: 'Overall Modification Limit:',
        class: 'budget-summary__col1__row1',
        isSet: () => true,
        amount: () => this.modificationMaxAmount,
        isInvalid: () => false,
        alertText: null,
        helpText: null
      },
      {
        title: 'Previously Modified:',
        class: 'budget-summary__col1__row2',
        isSet: () => true,
        amount: () => this.proposal.total_revised_amount || 0,
        isInvalid: () => false,
        alertText: null,
        helpText: null
      },
      {
        title: 'Currently Modifying:',
        class: 'budget-summary__col1__row3',
        isSet: () => true,
        amount: () => this.totalModified,
        isInvalid: () => this.modificationRemaining < 0,
        alertText: () => this.modificationLimitExceededText,
        helpText: () => this.modificationHelpText
      },
      {
        title: 'Currently Added:',
        class: 'budget-summary__col2__row1',
        isSet: () => true,
        amount: () => {
          const modifications = this.getModifiedBudgets();
          const additions = modifications.filter(budgetItem => budgetItem.revised_amount > budgetItem.direct_amount);
          return additions.reduce((total, budgetItem) => total + (budgetItem.revised_amount - budgetItem.direct_amount), 0);
        },
        isInvalid: () => false,
        alertText: null,
        helpText: null
      },
      {
        title: 'Currently Removed:',
        class: 'budget-summary__col2__row2',
        isSet: () => true,
        amount: () => {
          const modifications = this.getModifiedBudgets();
          const removals = modifications.filter(budgetItem => budgetItem.direct_amount > budgetItem.revised_amount);
          return removals.reduce((total, budgetItem) => total + (budgetItem.direct_amount - budgetItem.revised_amount), 0);
        },
        isInvalid: () => false,
        alertText: null,
        helpText: null
      },
      {
        title: 'Redistribution Discrepancy:',
        class: 'budget-summary__col2__row3',
        isSet: () => true,
        amount: () => null,
        isInvalid: () => this.totalModifiedBudget !== this.totalAmount,
        alertText: () => this.modificationTotalsAlertText,
        helpText: () => this.totalModifiedBudget !== this.totalAmount ? null : 'None'
      },
    ];
  }

  public getInstitutionName(id: number): string {
    return this.lookupService.getInstitutionName(id);
  }

  public getObjectCodeName(id: number): string {
    return this.objectCodeOptions.find(o => o.value === id).label;
  }

}
