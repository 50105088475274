<ng-container *ngIf="(initialized$ | async)" [ngSwitch]="!isPreview">
  <!-- Edit -->
  <ng-container *ngSwitchCase="true">
    <app-budget-summary *ngIf="guidance && !guidance.isTemplate"
      [summaryTitle]="summaryTitle"
      [summaryEntries]="isModifying ? budgetSummaryEntries : undefined"
      [template]="isModifying ? undefined : budgetSummaryTable"
      [canEdit]="canEdit"
      [canModify]="!showSaveModificationsModal && canModify"
      [maxModifyReached]="totalModified === modificationMaxAmount"
      [hasUnsavedModifications]="hasUnsavedModifications"
      [isModifying]="isModifying"
      (modify)="handleModify($event)"
      [amountRemaining]="amountRemaining">
    </app-budget-summary>

    <inline-alert *ngIf="showAllSectorsAlert" level="warning" [small]="true">
      Over {{allSectorsLimit}}% of your plan's total budget is budgeted to a Work Plan Activity/Activities with "Across All Sectors" selected as the
      Regional Priority Sector. Please be sure your "Brief description of expenditure" is detailed and sufficient.
    </inline-alert>

    <h2 class="header-title h2 mb-l" id="qa_tab_title">Budget</h2>
    <po-guidance-text [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>
    <ng-container *ngIf="hasUnsavedModifications" [ngTemplateOutlet]="unsavedModifications"></ng-container>

    <div *ngIf="yearForm" [formGroup]="yearForm" class="segmented-control segmented-control--stand-alone margin-bottom">
      <ul class="segmented-control__list" role="radiogroup">
        <li *ngFor="let year of yearOptions; let i=index;" class="segmented-control__item">
          <input #radio id="budget_year_option_{{i}}" class="segmented-control__input"
            formControlName="selectedYear" [value]="year.id"
            type="radio"/>
          <label for="budget_year_option_{{i}}" class="segmented-control__label"
            [attr.aria-checked]="radio.checked" attr.aria-label="View budget items for {{year.name}}">
            {{year.name}}
          </label>
        </li>
      </ul>
    </div>

    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>

  <!-- Preview -->
  <ng-container *ngSwitchCase="false">
    <div class="card">
      <div class="card-title">
        <h3>Budget</h3>
      </div>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- Content -->
<ng-template #content>
  <app-budget-v2-card *ngFor="let institution of institutions; trackBy: trackById"
    [isPreview]="isPreview"
    [canEdit]="canModify ? isModifying : canEdit"
    [proposalId]="proposal.id"
    [institution]="institution"
    [yearId]="selectedYearId"
    [preSorted]="true"
    [budgetItems]="isModifying ? filteredAndSortedBudgetItems(institution.id, true) : filteredAndSortedBudgetItems(institution.id)"
    [isModifying]="isModifying"
    [modifiableObjectCodeOptions]="getModifiableObjectCodes(institution.id)"
    [objectCodeOptions]="objectCodeOptions"
    [activityOptions]="activityOptions"
    [allowCloning]="allowCloning"
    [disableCloning]="shouldDisableCloning(institution.id)"
    (cloneFromPrevious)="copyPreviousBudgets(institution.id, selectedYearId)">
  </app-budget-v2-card>
</ng-template>

<!-- Summary -->
<ng-template #budgetSummaryTable>
  <table class="po-table">
    <thead>
      <th scope="col" class="col-15-rem">Budget Summary Total</th>
      <th *ngIf="isDecertified" scope="col" class="col-25-rem align-right">Certified Budget Amount</th>
      <th scope="col" class="col-25-rem align-right">Budget Amount</th>
      <th scope="col" class="col-25-rem align-right">"Across All Sectors" (% of total)</th>
    </thead>
    <tbody>
      <td>Total Expenditure</td>
      <td *ngIf="isDecertified" class="align-right">{{ (+totalAmount - +availableFunding?.requestedAmount) | currency:'USD':'symbol':'1.0' }}</td>
      <td [ngClass]="{ 'error-text': exceedsFunding }" class="align-right">
        <div class="flex flex--align-items-center flex--justify-content-end">
          <icon *ngIf="exceedsFunding" iconId="alert--fill" class="icon--error margin-right-xs"></icon>
          {{ +totalAmount | currency:'USD':'symbol':'1.0' }}
        </div>
      </td>
      <td class="align-right">
        {{ +allSectorsAmount | currency:'USD':'symbol':'1.0' }}
        <strong>({{allSectorsPercentage}}%)</strong>
        <icon *ngIf="showAllSectorsAlert" iconId="alert--fill" class="icon--warning icon--vertical-align margin-left-sm"></icon>
      </td>
    </tbody>
  </table>
  <div *ngIf="exceedsFunding" class="error-text">
    Plan cannot be submitted because your Budget Amount<ng-container *ngIf="isDecertified"> increase</ng-container>
    ({{ +availableFunding.requestedAmount | currency:'USD':'symbol':'1.0' }})
    exceeds the available funds of your {{ isRegional ? 'region' : 'district' }} by
    {{ (+availableFunding.requestedAmount - +availableFunding.amountRemaining) | currency:'USD':'symbol':'1.0' }}.
  </div>
</ng-template>

<ng-template #previewAlert>
  <inline-alert level="warning" [small]="true">
    This plan's budget for "Across All Sectors" is  over the {{allSectorsLimit}}% limit defined in program settings.
  </inline-alert>
</ng-template>

<ng-template #unsavedModifications>
  <inline-alert level="warning" [small]="true">
    <p class="no-margin" [attr.data-invalid]="true">{{ modificationsUnsavedText }}</p>
  </inline-alert>
</ng-template>

<!-- Save Modifications Modal -->
<full-page-modal *ngIf="showSaveModificationsModal"
  small="true"
  heading="Modifications Summary"
  button-action="Save Changes"
  [button-action-disabled]="disableSaveModify"
  button-cancel="Cancel"
  (action)="updateBudgetModifications()"
  (cancel)="dismissModificationsModal()"
  (dismiss)="dismissModificationsModal()">

  <dl class="dl-horizontal padding-bottom">
    <dt>Grant Funds Modified</dt>
    <dd>
      {{ +totalModified | currency:'USD':'symbol':'1.0' }} {{ modificationHelpText }}
      <span *ngIf="disableSaveModify" class="error-text error-text--inline margin-left-xs">
        {{ modificationsModalErrorText }}
      </span>
    </dd>
  </dl>

  <div *ngFor="let institution of modifiedBudgetItemsByInstitution" class="padding-bottom-xs">
    <table class="po-table po-table--fixed po-table--sticky-header po-table--captioned">
      <caption>{{ getInstitutionName(institution.id) }}</caption>
      <thead>
        <tr>
          <th scope="col">Expenditure Type</th>
          <th scope="col" class="col-8-rem">Year</th>
          <th scope="col" class="col-10-rem">Old</th>
          <th scope="col" class="col-10-rem">New</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let budgetItem of institution.budgets">
          <td>{{ getObjectCodeName(budgetItem.object_code_id) }}</td>
          <td>{{ budgetItem.duration_id }}</td>
          <td>{{ +budgetItem.direct_amount | currency:'USD':'symbol':'1.0' }}</td>
          <td>{{ +budgetItem.revised_amount | currency:'USD':'symbol':'1.0' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <form [formGroup]="saveModificationsForm" autocomplete="off" class="margin-top">
    <radio-check [labelText]="saveModificationCheckboxText">
      <input inputRef id="qa_can_save_checkbox" type="checkbox" formControlName="canSave" aria-required="true" required>
    </radio-check>
  </form>
</full-page-modal>

<!-- Budget Modification Discard Changes Alert -->
<alert *ngIf="showModificationsDiscardAlert"
  button-action="Discard Changes"
  button-dismiss="Cancel"
  level="warning"
  (action)="updateBudgetModifications(false)"
  (dismiss)="showModificationsDiscardAlert = false;">
  <p>Are you sure you want to discard your Grant Funds Modifications?</p>
</alert>