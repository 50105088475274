<div class="card">
  <div class="card-title" [ngSwitch]="isPreview">
    <ng-container *ngSwitchCase="false">
      <h3 class="margin-bottom-sm">{{labels['card']}}</h3>
      <div *ngIf="!!helpText['card']" class="help-text" [attr.data-invalid]="!hasVisionGoal">
        <p [innerHtml]="helpText['card'] | safeHtml"></p>
      </div>
    </ng-container>
    <h3 *ngSwitchCase="true">{{labels['card_preview']}}</h3>
  </div>

  <input-group [control]="form" headingText="Goals & Metrics" [legendHidden]="true" class="no-margin">
    <show-errors [control]="form" class="error-text"></show-errors>
    <ng-container *ngFor="let goal of successGoals" [ngTemplateOutlet]="goalCard" [ngTemplateOutletContext]="{ goal: goal }"></ng-container>
  </input-group>
</div>

<ng-template #goalCard let-goal="goal">
  <form *ngIf="form" [formGroup]="form.get(goal.name)" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
    <div class="card">
      <radio-check [labelText]="formatGoalLabel(goal)" [canEdit]="canEdit && !requiredGoals.includes(goal.name)" readOnlyType="checked-unchecked">
        <input inputRef id="success_goal{{goal.id}}" type="checkbox" class="checkbox" formControlName="selected" (change)="goalSelectChanged(goal, isPerkins1Cv2)">
      </radio-check>

      <div *ngIf="isPerkins1Cv2" class="margin-left margin-top">
        <p class="label-text">Select all relevant outcomes</p>
        <input-group [control]="form.get(goal.name)?.get('performance_outcomes')" headingText="Outcomes" [legendHidden]="true" class="no-margin">
          <show-errors [control]="form.get(goal.name)?.get('performance_outcomes')" class="error-text"></show-errors>
          <ng-container *ngFor="let outcome of goal.child_outcomes" [formGroup]="form.get(goal.name)?.get('performance_outcomes')?.get('outcome_' + outcome.id)">
            <radio-check [labelText]="formatOutcomeLabel(outcome)" [canEdit]="canEdit" readOnlyType="checked-unchecked">
              <input inputRef id="outcome{{outcome.id}}" type="checkbox" class="checkbox" formControlName="outcome_selected">
            </radio-check>
            <ng-container *ngIf="outcome.child_metrics?.length">
              <input-group [control]="form.get(goal.name)?.get('performance_outcomes')?.get('outcome_' + outcome.id).get('child_outcomes')" headingText="Child Outcomes" [legendHidden]="true" class="no-margin">
                <show-errors [control]="form.get(goal.name)?.get('performance_outcomes')?.get('outcome_' + outcome.id).get('child_outcomes')" class="error-text margin-left"></show-errors>
                <div *ngFor="let childOutcome of outcome.child_metrics" [formGroup]="form.get(goal.name)?.get('performance_outcomes')?.get('outcome_' + outcome.id)?.get('child_outcomes')" class="margin-left">
                  <radio-check [labelText]="formatOutcomeLabel(childOutcome, true)" [canEdit]="canEdit" readOnlyType="checked-unchecked">
                    <input inputRef id="outcome{{childOutcome.id}}" type="checkbox" class="checkbox" formControlName="outcome_{{childOutcome.id}}">
                  </radio-check>
                </div>
              </input-group>
            </ng-container>
          </ng-container>
        </input-group>
      </div>

      <ng-container *ngIf="requireMetricsForGoal(goal.id)">
        <ng-container *ngIf="!isPreview">
          <div @collapsible>
            <ng-container [ngTemplateOutlet]="goalCardContent" [ngTemplateOutletContext]="{ goal: goal }"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="isPreview">
          <ng-container [ngTemplateOutlet]="goalCardContent" [ngTemplateOutletContext]="{ goal: goal }"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
</ng-template>

<ng-template #goalCardContent let-goal="goal">
  <ng-container [formGroup]="form.get(goal.name)">
    <input-group headingText="Metrics"
      formGroupName="metrics"
      [control]="getMetricsFormControl(goal.name)"
      [errorsOnUntouched]="!firstTouch"
      [legendHidden]="true"
      [required]="true">
      <ng-container *ngFor="let select of metricsOptions; let last = last">
        <po-select id="{{select.metricName}}{{goal.id}}"
          [labelText]="labels[select.metricName]"
          placeholderText="Select {{labels[select.metricName]}}"
          [ngClass]="{ 'margin-bottom-sm': !last }"
          [canEdit]="canEdit"
          [options]="threeYearPlan ? metricsOptionsByArea[goal.name] : select.options"
          [selectMultiple]="true"
          [removeAlertTmpl]="deleteMetricAlert"
          (optionSelected)="addAttribute('success_metrics', goal.id, $event)"
          (optionRemoved)="removeAttribute('success_metrics', goal.id, $event)">
          <select inputRef class="po-select__select" formControlName="{{select.metricName}}"></select>
        </po-select>
      </ng-container>
    </input-group>

    <po-select *ngIf="collectPerformanceOutcomes && !isPerkins1Cv2" id="performance_outcomes{{goal.id}}"
      labelText="Performance Outcomes"
      placeholderText="Select Performance Outcomes"
      [canEdit]="canEdit"
      [options]="outcomeOptions"
      [selectMultiple]="true"
      (optionSelected)="addAttribute('performance_outcomes', goal.id, $event)"
      (optionRemoved)="removeAttribute('performance_outcomes', goal.id, $event)">
      <select inputRef class="po-select__select" formControlName="performance_outcomes" aria-required="true" required></select>
    </po-select>
  </ng-container>
</ng-template>

<ng-template #deleteMetricAlert let-optionName="optionName">
  <ng-container [ngSwitch]="!!optionName">
    <p *ngSwitchCase="true">
      Are you sure you want to delete the metric <strong>{{optionName}}</strong>?
      <strong>Associated targets and activities will be affected.</strong>
    </p>
    <p *ngSwitchCase="false">
      Are you sure you want to delete this metric?
    </p>
  </ng-container>
</ng-template>

<alert *ngIf="showUnselectAlert"
       button-action="Ok"
       button-dismiss="Cancel"
       level="warning"
       (action)="toggleSelected(goalToDelete)"
       (dismiss)="toggleShowUnselectAlert(undefined)">
  <p>
    Are you sure you want to deselect the {{goalToDelete.name}} goal?
  </p>
</alert>
